/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import {
  Box,
  Breadcrumbs,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Radio,
  TextField,
  TextareaAutosize,
  Typography
} from '@mui/material'
import LanguageStrings from '../../../../i18n/locales'
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import React from 'react'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import { Button } from '../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { CreateSFTPConfigurationservice, getConfigurationDropdownMetadata, getPublicKeyData } from '../../redux/actionCreators'
import { type PublicKeyDataState } from '../../redux/getPublicKeySlice'
import { type CreateSFTPConfigurationState } from '../../redux/CreateSftpConfigurationSlice'
import { type ConfigurationMetadataDropDownState } from '../../redux/configurationMetaDataSlice'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../../store'
import { type SFTPConfigurationData } from '../../types'
import Modal from '../../../../components/Modal'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import '../ReportSchedulerComponents/scheduler.css'
import { isValidName, isValidPort, isValidFolderPath } from '../../../../utils/validator'
import { isValidIp } from '../../../data-publisher/components/addConfig/fileValidation'
import MoreInfo from '../../../../assets/More-Info.svg'
import { styled } from '@mui/material/styles'
import forge from 'node-forge'

const CreateSFTPStrings = LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings.SchedulerStrings.CreateSFTPFormStrings

const CreateSFTPButtonStrings = LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings.SchedulerStrings.CreateSchedulerFormStrings

const style = {
  width: '99%',
  borderRadius: '10px',
  border: '1px solid #101010',
  background: '#272727',
  boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '16px',
  marginBottom: '16px',
  padding: '24px'
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 270,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    fontFamily: 'Honeywell Sans Web'
  }
}))

const CreateSftpConfiguration = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const [protocolSpecificationName, setProtocolSpecificationName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [selectProtocol, setselectProtocol] = React.useState<Option | null>(null)
  const [selectProtocolOptions, setSelectProtocolOptions] = React.useState<Option[]>([])
  const [protocolName, setProtocolName] = React.useState('')
  const [ipAddress, setIpAddress] = React.useState('')
  const [port, setPort] = React.useState('')
  const [userName, setUserName] = React.useState('')
  const [password, setpassword] = React.useState('')
  const [SSHKey, setSSHKey] = React.useState('')
  const [checked, setChecked] = React.useState(false)
  const [PublicKeystate, setPublicHKeystate] = React.useState('')
  const [folderPath, setFolderPath] = React.useState('')
  const [confirm, setconfirm] = React.useState(false)
  const [showSuccessPopUp, setshowSuccessPopUp] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState('a')
  const [displayMaskValue, setDisplayMaskValue] = React.useState('')
  const [reqError, setReqError] = React.useState<boolean>(false)
  const DropDownMetaData = useSelector((state: RootState) => state?.ConfigurationMetadata?.configurationMetadata?.protocols)
  const ProocolHttpStatus = useSelector((state: RootState) => state?.SFTPConfigurationData)
  const publicKey = useSelector((state: RootState) => state.PublicKeyData.PublicKeyData)

  const initialState = {
    connectionName: null,
    description: null,
    protocolTypeId: null,
    protocolName: null,
    ipAddress: null,
    port: null,
    userName: null,
    password: null,
    sshKey: null,
    folderPath: null,
    publicKey: null
  }

  const [addState, dispatch] = React.useReducer(
    (curVal: any, newVal: any) => ({ ...curVal, ...newVal }),
    initialState
  )

  React.useEffect(() => {
    props.getConfigurationDropdownMetadata()
    props.getPublicKeyData()
  }, [])

  React.useEffect(() => {
    if (DropDownMetaData !== undefined && DropDownMetaData?.length > 0) {
      const ProtocolOption: Option[] = []
      DropDownMetaData?.forEach((item: any) => {
        ProtocolOption.push({
          value: item.protocolTypeId,
          label: item.protocolTypeName
        })
      })
      setSelectProtocolOptions(ProtocolOption)
    }
  }, [DropDownMetaData])

  const handleconnectionName = (name: string, value: string): void => {
    setProtocolSpecificationName(value)
    dispatch({ ...addState, connectionName: value })
  }

  const handleDescription = (name: string, value: string): void => {
    setDescription(value)
    dispatch({ ...addState, description: value })
  }

  const handleProtocol = (selectedValue: Option): void => {
    setselectProtocol(selectedValue)
    dispatch({ ...addState, protocolTypeId: selectedValue.value })
  }

  const handleProtocolName = (name: string, value: string): void => {
    setProtocolName(value)
    dispatch({ ...addState, protocolName: value })
  }

  const handleIPAddress = (name: string, value: string): void => {
    setIpAddress(value)
    dispatch({ ...addState, ipAddress: value })
  }

  const handlePortNumber = (name: string, value: string): void => {
    setPort(value)
    dispatch({ ...addState, port: value })
  }

  const handleUserName = (name: string, value: string): void => {
    setUserName(value)
    const publicKeyObj = forge.pki.publicKeyFromPem(publicKey !== undefined ? publicKey : '')
    dispatch({
      ...addState,
      userName: forge.util.encode64(publicKeyObj.encrypt(value, 'RSA-OAEP', {
        md: forge.md.sha256.create()
      }))
    })
  }

  const handlePassword = (name: string, value: string): void => {
    setpassword(value)
    const publicKeyObj = forge.pki.publicKeyFromPem(publicKey !== undefined ? publicKey : '')
    dispatch({
      ...addState,
      password: forge.util.encode64(publicKeyObj.encrypt(value, 'RSA-OAEP', {
        md: forge.md.sha256.create()
      })),
      sshKey: null
    })
  }

  const handleSSHkey = (name: string, value: string): void => {
    setDisplayMaskValue(value.replace(/./g, '*'))
    setSSHKey(value)
    dispatch({ ...addState, sshKey: btoa(value), password: null })
  }

  const handleFolderPath = (name: string, value: string): void => {
    setFolderPath(value)
    dispatch({ ...addState, folderPath: value })
  }

  const handlePublicKey = (name: string, value: string): void => {
    setPublicHKeystate(value)
    dispatch({ ...addState, publicKey: btoa(value) })
  }

  const handlePublicChange = (): void => {
    setChecked(!checked)
  }

  const handleOkandClose = (): void => {
    setshowSuccessPopUp(false)
    setconfirm(false)
    navigate('/configuration-protocol')
    clearAllDetails()
  }

  const handleOkandClosError = (): void => {
    setshowSuccessPopUp(false)
    setconfirm(false)
    navigate('/create-sftp-configuration')
  }

  const clearAllDetails = (): void => {
    setProtocolSpecificationName('')
    setDescription('')
    setselectProtocol(null)
    setProtocolName('')
    setIpAddress('')
    setPort('')
    setUserName('')
    setpassword('')
  }

  const isValidlength = (value: string): boolean => {
    return value.length > 5 && value.length < 100
  }

  const isValidPasswordlength = (value: string): boolean => {
    return value.length > 8 && value.length < 15
  }

  const isValidPathlength = (value: string): boolean => {
    return value.length > 8 && value.length < 500
  }

  const handleconfirmClick = (): void => {
    props.CreateSFTPConfigurationservice(addState)
    setconfirm(false)
    setshowSuccessPopUp(true)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedValue(event.target.value)
  }

  const handleCreateConfigurationWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (ProocolHttpStatus.httpStatus === 201) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={CreateSFTPStrings.CreateTitle}
          LabelPositive={'Ok'}
          onClickPositive={() => {
            handleOkandClose()
          }}
          onClose={() => {
            handleOkandClose()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {ProocolHttpStatus.httpStatus === 201
              ? CreateSFTPStrings.SucessfullyCreated
              : ProocolHttpStatus.error}
          </p>
        </Modal>
      )
    } else if (ProocolHttpStatus.isLoading) {
      return (
        <Modal title={'Loading...'}>
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (ProocolHttpStatus.httpStatus === 400) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={'Error'}
          LabelPositive={'Ok'}
          onClickPositive={() => {
            handleOkandClosError()
          }}
          onClose={() => {
            handleOkandClosError()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{ProocolHttpStatus.error}</p>
        </Modal>
      )
    } else if (ProocolHttpStatus.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (ProocolHttpStatus.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={CreateSFTPStrings.CreateTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandClosError()
          }}
          onClose={() => {
            handleOkandClosError()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{ProocolHttpStatus.error}</p>
        </Modal>
      )
    }
  }

  const validateFields = (): void => {
    if (
      addState.connectionName !== null &&
      addState.protocolTypeId !== null &&
      addState.protocolName !== null &&
      addState.ipAddress !== null &&
      addState.port !== null &&
      addState.userName !== null &&
      addState.folderPath !== null
    ) {
      if (addState.connectionName) {
        if (!isValidName(addState.connectionName)) {
          setReqError(true)
          return
        }
      }
      if (addState.connectionName) {
        if (!isValidlength(addState.connectionName)) {
          setReqError(true)
          return
        }
      }
      if (addState.description) {
        if (!isValidName(addState.description)) {
          setReqError(true)
          return
        }
      }
      if (addState.description) {
        if (!isValidlength(addState.description)) {
          setReqError(true)
          return
        }
      }
      if (addState.protocolName) {
        if (!isValidName(addState.protocolName)) {
          setReqError(true)
          return
        }
      }
      if (addState.protocolName) {
        if (!isValidlength(addState.protocolName)) {
          setReqError(true)
          return
        }
      }
      if (addState.ipAddress) {
        if (!isValidIp(addState.ipAddress)) {
          setReqError(true)
          return
        }
      }
      if (addState.port) {
        if (!isValidPort(addState.port)) {
          setReqError(true)
          return
        }
      }
      if (addState.port) {
        if (!isValidPort(addState.port)) {
          setReqError(true)
          return
        }
      }
      if (userName) {
        if (!isValidName(userName)) {
          setReqError(true)
          return
        }
      }
      if (addState.userName) {
        if (!isValidlength(userName)) {
          setReqError(true)
          return
        }
      }
      if (password) {
        if (!isValidPasswordlength(password)) {
          setReqError(true)
          return
        }
      }
      if (selectedValue === 'b') {
        if (addState.password === null) {
          setReqError(true)
          return
        }
      }
      if (selectedValue === 'a') {
        if (addState.sshKey === null) {
          setReqError(true)
          return
        }
      }
      if (addState.folderPath) {
        if (!isValidFolderPath(addState.folderPath)) {
          setReqError(true)
          return
        }
      }
      if (checked) {
        if (PublicKeystate === '') {
          setReqError(true)
          return
        }
      }
      setconfirm(true)
    } else {
      setReqError(true)
    }
  }

  return (
    <>
      <div
        role="presentation"
        data-testid="breadcrumbs"
        style={{ width: '100%' }}
      >
        <Breadcrumbs
          aria-name="breadcrumb"
          sx={{
            color: '#f0f0f0',
            fontSize: '12px',
            marginBottom: '14px',
            fontFamily: 'Honeywell Sans Web',
            marginTop: '12px'
          }}
        >
          <Link
            underline="hover"
            color="inherit"
            data-testid="scheduler-breadcrumb"
            onClick={() => {
              navigate('/datapub')
            }}
          >
            {CreateSFTPStrings.dataPublisherSettings}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
            onClick={() => {
              navigate('/configuration-protocol')
            }}
          >
            {CreateSFTPStrings.configurationProtocol}
          </Link>
          <Typography
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
          >
            {CreateSFTPStrings.addConfigurationProtocol}
          </Typography>
        </Breadcrumbs>
      </div>
      <div style={{ display: 'flex' }}>
        <ArrowBackIos
          sx={{ cursor: 'pointer' }}
          data-testid="back-arrow"
            onClick={() => {
              navigate('/configuration-protocol')
            }}
        />
        <div style={{ marginBottom: '32px' }}>
          <span
            data-testid="schedule-title"
            style={{
              color: '#f0f0f0',
              fontSize: '24px',
              fontWeight: '900',
              paddingRight: '16px',
              marginRight: '10px'
            }}
          >
            {CreateSFTPStrings.addConfigurationProtocol}
          </span>
        </div>
      </div>
      <Box sx={style} className="add-Scheduler-container">
        <Typography
          color="inherit"
          sx={{
            fontSize: '16px',
            textTransform: 'uppercase',
            fontWeight: '800',
            fontFamily: 'Honeywell Sans Web'
          }}
        >
          {CreateSFTPStrings.configurationSpecifications}
        </Typography>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSFTPStrings.name}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <TextField
                id="configuration-name"
                variant="outlined"
                data-testId="configuration-name"
                placeholder="Enter Configuration Name"
                InputLabelProps={{
                  disabled: true
                }}
                value={protocolSpecificationName}
                onChange={(e) => {
                  handleconnectionName('connectionName', e.target.value)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {addState.connectionName !== null && !isValidName(addState.connectionName) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                    {CreateSFTPButtonStrings.enterValidText}
                  </span>
                </div>
              )}
              {reqError && !addState.connectionName && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
              )}
              {addState.connectionName !== null && !isValidlength(addState.connectionName) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                    {CreateSFTPButtonStrings.enterMoreText}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSFTPStrings.description}
              </span>
              <TextField
                id="configuration-desc"
                variant="outlined"
                data-testId="configuration-desc"
                placeholder="Enter Description"
                InputLabelProps={{
                  disabled: true
                }}
                value={description}
                onChange={(e) => {
                  handleDescription('description', e.target.value)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {addState.description !== null && !isValidlength(addState.description) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                    {CreateSFTPButtonStrings.enterMoreText}
                  </span>
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Box>
      <Box sx={style} className="add-Scheduler-container">
        <Typography
          color="inherit"
          sx={{
            fontSize: '16px',
            textTransform: 'uppercase',
            fontWeight: '800',
            fontFamily: 'Honeywell Sans Web'
          }}
        >
          {CreateSFTPStrings.connectionSettings}
        </Typography>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSFTPStrings.protocol}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <GenXDropdown
                placeholder="Select Protocol"
                value={selectProtocol}
                options={selectProtocolOptions}
                onSelect={handleProtocol}
                width={270}
              />
              {reqError && !addState.protocolTypeId && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSFTPStrings.protocolName}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <TextField
                id="configuration-Pname"
                variant="outlined"
                data-testId="configuration-Pname"
                placeholder="Enter Protocol Name"
                InputLabelProps={{
                  disabled: true
                }}
                value={protocolName}
                onChange={(e) => {
                  handleProtocolName('protocolName', e.target.value)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {addState.protocolName !== null && !isValidName(addState.protocolName) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                    {CreateSFTPButtonStrings.enterValidText}
                  </span>
                </div>
              )}
              {reqError && !addState.protocolName && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
              )}
              {addState.protocolName !== null && !isValidlength(addState.protocolName) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                    {CreateSFTPButtonStrings.enterMoreText}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSFTPStrings.SFTPIPAddress}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <TextField
                id="configuration-Ip"
                variant="outlined"
                data-testId="configuration-Ip"
                placeholder="Enter IP Address"
                InputLabelProps={{
                  disabled: true
                }}
                value={ipAddress}
                onChange={(e) => {
                  handleIPAddress('ipAddress', e.target.value)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {reqError && !addState.ipAddress && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
              )}
              {addState.ipAddress !== null && !isValidIp(addState.ipAddress) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                    {CreateSFTPStrings.ValidIP}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSFTPStrings.SFTPPort}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <TextField
                id="configuration-port"
                variant="outlined"
                data-testId="configuration-port"
                placeholder="Enter Port"
                InputLabelProps={{
                  disabled: true
                }}
                inputProps={{ maxLength: 5 }}
                value={port}
                onChange={(e) => {
                  handlePortNumber('port', e.target.value)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {reqError && !addState.port && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
              )}
              {addState.port !== null && !isValidPort(addState.port) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                    {CreateSFTPStrings.ValidPort}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSFTPStrings.username}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <TextField
                id="configuration-username"
                variant="outlined"
                data-testId="configuration-username"
                placeholder="Enter Username"
                InputLabelProps={{
                  disabled: true
                }}
                value={userName}
                onChange={(e) => {
                  handleUserName('userName', e.target.value)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {reqError && !userName && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
              )}
              {userName !== '' && !isValidlength(userName) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                  {CreateSFTPButtonStrings.enterMoreText}
                  </span>
                </div>
              )}
              {userName !== '' && !isValidName(userName) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                  {CreateSFTPButtonStrings.enterValidText}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSFTPStrings.folderPath}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <TextField
                id="configuration-Folder-Path"
                variant="outlined"
                data-testId="configuration-Folder-Path"
                placeholder="Enter Folder Path *"
                InputLabelProps={{
                  disabled: true
                }}
                value={folderPath}
                onChange={(e) => {
                  handleFolderPath('folderPath', e.target.value)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {reqError && !addState.folderPath && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
              )}
              {addState.folderPath !== null && !isValidFolderPath(addState.folderPath) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                  {CreateSFTPStrings.folderPath_error_msg}
                  </span>
                </div>
              )}
              {addState.folderPath !== null && !isValidPathlength(addState.folderPath) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                  {CreateSFTPStrings.FolderPath_validate_msg}
                  </span>
                </div>
              )}
            </Grid>
            <Grid item md={3}>
            <div className='radio-box'>
            <Checkbox
              checked={checked}
              onChange={handlePublicChange}
              inputProps={{ 'aria-label': 'controlled', id: 'select-item' }}
              style={{ color: '#ffffff73' }}
            />
              </div>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }} >
                {CreateSFTPStrings.Publicj_key_title}
                <HtmlTooltip className='more-info-tooltip' title={CreateSFTPStrings.Public_Key_More_info} placement="top" arrow>
                  <IconButton>
                  <span className='more-info'><img src={MoreInfo} height={20} width={20} /></span>
                  </IconButton>
                </HtmlTooltip>
              </span>
              <TextareaAutosize
                style={{
                  backgroundColor: '#272727',
                  color: '#ffffff',
                  border: '1px solid #ffffff54',
                  borderRadius: '4px',
                  padding: '8px',
                  width: '100%',
                  minHeight: '90px',
                  height: '90px'
                }}
                value={PublicKeystate}
                disabled={!checked}
                placeholder="Enter PublicKey"
                onChange={(e) => {
                  handlePublicKey('PublicKey', e.target.value)
                }}
              />
            {reqError && PublicKeystate === '' && checked && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
            )}
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={1}>
          <Grid item md={3}>
            <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                {CreateSFTPStrings.Authentication}
                <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <div className='radio-box'>
            <Radio
                  checked={selectedValue === 'a' }
                  onChange={handleChange}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'A' }}
                  style={{ color: '#ffffff' }}
                />
              </div>
              <span style={{ fontSize: '15px', padding: 10, display: 'block', fontStyle: 'italic' }}>
                {CreateSFTPStrings.SSH_Key_title}
              </span>
              <TextareaAutosize
                id="configuration-SSHkey"
                data-testId="configuration-SSHkey"
                placeholder="Enter SSH key"
                disabled={ selectedValue === 'b' }
                value={displayMaskValue}
                onChange={(e) => {
                  handleSSHkey('SSHKey', e.target.value)
                }}
                autoComplete="off"
                className="configuration-name-class"
                style={{
                  backgroundColor: '#272727',
                  color: '#ffffff',
                  border: '1px solid #ffffff54',
                  borderRadius: '4px',
                  padding: '8px',
                  width: '100%',
                  minHeight: '40px',
                  height: '40px'
                }}
              />
              {reqError && !SSHKey && selectedValue !== 'b' && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
              )}
            </Grid>
            <Grid item md={3} style={{ marginTop: '40px' }}>
            <div className='radio-box'>
                <Radio
                  checked={selectedValue === 'b'}
                  onChange={handleChange}
                  value="b"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'B' }}
                  style={{ color: '#ffffff' }}
                />
              </div>
              <span style={{ fontSize: '15px', padding: 10, display: 'block', fontStyle: 'italic' }}>
                {CreateSFTPStrings.Password_title}
              </span>
              <TextField
                id="configuration-password"
                variant="outlined"
                data-testId="configuration-password"
                placeholder="Enter Password"
                type="password"
                disabled={ selectedValue === 'a' }
                InputLabelProps={{
                  disabled: true
                }}
                value={password}
                onChange={(e) => {
                  handlePassword('password', e.target.value)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {reqError && !password && selectedValue !== 'a' && (
                <div className='error-response'>
                  <span className='error-upload-message'>{CreateSFTPButtonStrings.required}</span>
                </div>
              )}
              {password !== '' && !isValidPasswordlength(password) && (
                <div className='error-response'>
                  <span className='error-upload-message'>
                  {CreateSFTPStrings.PasswordError}
                  </span>
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Box>
      <Box sx={style} className="add-configuration-container">
        <Grid container spacing={1}>
          <Grid style={{ textAlign: 'left' }} item md={6}>
            <Button
              type="hollow"
              data-testid="cancel-btn"
              onClick={() => {
                navigate('/configuration-protocol')
                clearAllDetails()
              }}
            >
              {CreateSFTPButtonStrings.CancelBtn}
            </Button>
          </Grid>
          <Grid style={{ textAlign: 'right' }} item md={6}>
            <Button
              onClick={() => {
                validateFields()
              }}
            >
              {CreateSFTPStrings.save_btn}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {confirm && (
        <Modal
          titleStyle={{ paddingRight: '2em', marginBottom: '20px' }}
          buttonTypeNegative="hollow"
          LabelPositive={CreateSFTPButtonStrings.ConfirmBtn}
          LabelNegative={CreateSFTPButtonStrings.CancelBtn}
          onClickPositive={() => {
            handleconfirmClick()
          }}
          onClose={() => {
            setconfirm(false)
          }}
          onClickNegative={() => {
            setconfirm(false)
          }}
          title={'Create Protocol Configuration'}
        >
          <div className="confirm-msg" style={{ marginBottom: '30px' }}>
            <span>{CreateSFTPStrings.CreateConfirm}</span>
          </div>
        </Modal>
      )}
      {showSuccessPopUp && handleCreateConfigurationWithFailure()}
    </>
  )
}

interface DispatchToProps {
  CreateSFTPConfigurationservice: (
    createReportSchedulerTemplatePostRequest: SFTPConfigurationData
  ) => void
  getConfigurationDropdownMetadata: () => void
  getPublicKeyData: () => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  CreateSFTPConfigurationservice: (
    createReportSchedulerTemplatePostRequest: SFTPConfigurationData
  ) =>
    dispatch(
      CreateSFTPConfigurationservice(createReportSchedulerTemplatePostRequest)
    ),
  getConfigurationDropdownMetadata: () =>
    dispatch(getConfigurationDropdownMetadata()),
  getPublicKeyData: () =>
    dispatch(getPublicKeyData())
})

interface StateToProps {
  SFTPConfigurationData: CreateSFTPConfigurationState
  ConfigurationMetadata: ConfigurationMetadataDropDownState
  PublicKeyData: PublicKeyDataState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  SFTPConfigurationData: state.SFTPConfigurationData,
  ConfigurationMetadata: state.ConfigurationMetadata,
  PublicKeyData: state.PublicKeyData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CreateSftpConfiguration)
