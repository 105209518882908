/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Checkbox,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  fabClasses,
  styled,
  tableCellClasses
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import React, { useEffect } from 'react'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../../store'
import { type SchdulerListDataState } from '../../redux/getAllSchdulerListSlice'
import { type CreateSchedulerFilterDropDownState } from '../../redux/reportSchedulerFilterDropdownSlice'
import { type DeleteSchedulerTemplateState } from '../../redux/deleteSchedulerSlice'
import { type PauseSchedulerTemplateState } from '../../redux/pauseSchedulerSlice'
import { type resumeSchedulerTemplateState } from '../../redux/resumeSchedulerSlice'
import Pagination from '../../../../components/TablePagination'
import { type SchduledListData } from '../../services/getAllSchdulerList'
import SearchBox from '../../../../components/SearchBox'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import './scheduler.css'
import FilterIcon from '../../../../assets/filterIcon.png'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import LanguageStrings from '../../../../i18n/locales'
import ReportViewHistory from './ReportViewHistory'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import { Button } from '../../../../components/Button'
import CloseIcon from '@mui/icons-material/Close'
import ReplayIcon from '@mui/icons-material/Replay'
import {
  getAllSchedulerFilterDropdown,
  getAllSchdulerListByFilterPara,
  getSchdulerListDetails,
  exportSchedulerGridTemplate,
  deleteSchedulerTemplate,
  pauseSchedulerTemplate,
  resumeSchedulerTemplate,
  getSchdulerListDetailsBySearch
} from '../../redux/actionCreators'
import { useAuth } from 'react-oidc-context'
import FileExport from '../../../../assets/exportIcon.svg'
import Modal from '../../../../components/Modal'
import { useNavigate } from 'react-router-dom'
import { visuallyHidden } from '@mui/utils'
import DoNotDisturb from '@mui/icons-material/DoNotDisturb'
import { parseDateInAOHstandard } from '../../../../customHooks/parseDate'
const SchedulerTableStrings =
  LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.SchedulerTableStrings
const SchedulerTableForms =
  LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.CreateSchedulerFormStrings
const SchedulerTable =
  LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.CreateSchedulerFormStrings

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

interface data {
  reportSchedulerId: string
  schedulerName: string
  templateName: string
  lastScheduleDate: string
  nextScheduleDate: string
  groupOrAsset: string
  groupOrAssetName: any
  lastScheduleStatus: string
  schedulerInterval: string
  recurrencePattern: string
}
interface EnhancedTableProps {
  numSelected: number
  rowCount: number
  SchduledList: SchduledListData[]
  order: Order
  orderBy: string
  onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof SchduledListData) => void
}

function EnhancedTableHead (props: EnhancedTableProps): any {
  const { order, orderBy, onRequestSort, numSelected, rowCount, SchduledList } = props
  // istanbul ignore next
  const createSortHandler =
    (newOrderBy: keyof SchduledListData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy)
    }
  return (SchduledList !== null && SchduledList !== undefined && <>
  <TableHead data-testid='table-header'>
        <TableRow>
          {headCells.map((headCell: HeadCell) => (
            <StyledTableCell
              key={headCell.id}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                hideSortIcon={!headCell.sortable}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
                aria-label={headCell.label}
                data-testid="test-sort"
                sx={{ cursor: headCell.sortable ? 'pointer' : 'text' }}
              >
                {headCell.label}
                {orderBy === headCell.id
                  ? (
                    // istanbul ignore next
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                    )
                  : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
  </>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
  sortable: boolean
}
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 10px',
    fontFamily: 'Honeywell Sans Web',
    lineHeight: '20px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '10px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'schedulerName',
    numeric: true,
    disablePadding: false,
    label: SchedulerTableStrings.schedulerName,
    sortable: true
  },
  {
    id: 'templateName',
    numeric: false,
    disablePadding: false,
    label: SchedulerTableStrings.templateName,
    sortable: true
  },
  {
    id: 'schedulerAssignment',
    numeric: true,
    disablePadding: false,
    label: SchedulerTableStrings.asset_type,
    sortable: true
  },
  {
    id: 'assetOrGroupValue',
    numeric: true,
    disablePadding: false,
    label: SchedulerTableStrings.groupOrAsset,
    sortable: true
  },
  {
    id: 'schedulerInterval',
    numeric: true,
    disablePadding: false,
    label: SchedulerTableStrings.schedulerInterval,
    sortable: true
  },
  {
    id: 'recurrencePattern',
    numeric: true,
    disablePadding: false,
    label: SchedulerTableStrings.recurrencePattern,
    sortable: true
  },
  {
    id: 'lastScheduleDate',
    numeric: true,
    disablePadding: false,
    label: SchedulerTableStrings.last_schedule_date,
    sortable: true
  },
  {
    id: 'nextScheduleDate',
    numeric: false,
    disablePadding: false,
    label: SchedulerTableStrings.next_schedule_date,
    sortable: true
  },
  {
    id: 'lastScheduleStatus',
    numeric: true,
    disablePadding: false,
    label: SchedulerTableStrings.last_schedule_status,
    sortable: true
  },
  {
    id: 'schedulerStatus',
    numeric: true,
    disablePadding: false,
    label: 'SCHEDULE STATUS',
    sortable: true
  },
  {
    id: 'ellipsisAction',
    numeric: true,
    disablePadding: false,
    label: '',
    sortable: false
  }
]

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const ReportScheduleTableList = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const [selected, setSelected] = React.useState<readonly number[]>([])
  const [page, setPage] = React.useState(1)
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [sort, setSort] = React.useState<Sort>({
    order: 'desc',
    orderBy: 'modifiedAt'
  })
  const [selectedRow, setSelectedRow] = React.useState<any>(null)
  const [selectedRowName, setSelectedRowName] = React.useState<any>(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [showScheduleHistoryPopUp, setShowScheduleHistoryPopUp] =
    React.useState(false)
  const [showfilter, setshowfilter] = React.useState(false)
  const [openFilter, setOpenFilter] = React.useState(false)
  const rowsPerPage = 10
  const auth = useAuth()
  const tableData = useSelector(
    (state: RootState) => state.SchdulerListData.SchdulerListData
  )
  const dataHttpStatus = useSelector(
    (state: RootState) => state.SchdulerListData.httpStatus
  )
  const schedulerNamesData = useSelector(
    (state: RootState) =>
      state.SchedulerFilterDropDownListData.SchedulerFilterDropDownListData
        ?.schedulerNames
  )
  const schedulerAssignmentsData = useSelector(
    (state: RootState) =>
      state.SchedulerFilterDropDownListData.SchedulerFilterDropDownListData
        ?.schedulerAssignments
  )
  const reportTemplatesData = useSelector(
    (state: RootState) =>
      state.SchedulerFilterDropDownListData.SchedulerFilterDropDownListData
        ?.reportTemplates
  )
  const ScheduleStatusData = useSelector(
    (state: RootState) =>
      state.SchedulerFilterDropDownListData.SchedulerFilterDropDownListData
        ?.lastScheduleStatus
  )
  const totalCount = useSelector(
    (state: RootState) => state.SchdulerListData.totalCount
  )
  const SchedulerDeleterStatus = useSelector(
    (state: RootState) => state.deleteScheduler
  )

  const pauseSchedulerStatus = useSelector(
    (state: RootState) => state.pauseScheduler
  )

  const resumeSchedulerStatus = useSelector(
    (state: RootState) => state.resumeScheduler
  )
  const search: any = ''
  const [confirm, setconfirm] = React.useState(false)
  const [schedulerName, setschedulerName] = React.useState<Option | null>(null)
  const [schedulerNameOptions, setschedulerNameOptions] = React.useState<
  Option[]
  >([])
  const [showSuccessPopUp, setshowSuccessPopUp] = React.useState(false)
  const [showSuccessPausePopUp, setshowSuccessPausePopUp] = React.useState(false)
  const [showSuccessResumePopUp, setshowSuccessResumePopUp] = React.useState(false)
  const [schedulerAssignments, setschedulerAssignments] =
    React.useState<Option | null>(null)
  const [schedulerAssignmentsOptions, setschedulerAssignmentsOptions] =
    React.useState<Option[]>([])
  const [reportTemplates, setreportTemplates] = React.useState<Option | null>(
    null
  )
  const [reportTemplatesOptions, setreportTemplatesOptions] = React.useState<
  Option[]
  >([])

  const [lastScheduleStatus, setlastScheduleStatus] =
    React.useState<Option | null>(null)
  const [lastScheduleStatusOptions, setlastScheduleStatusOptions] =
    React.useState<Option[]>([])

  const initialState = {
    schedulerName: null,
    schedulerAssignments: null,
    reportTemplates: null,
    lastScheduleStatus: null
  }

  const [addState, dispatch] = React.useReducer(
    (curVal: any, newVal: any) => ({ ...curVal, ...newVal }),
    initialState
  )

  React.useEffect(() => {
    if (isFilterActive()) {
      showFilterDetails()
    } else {
      getSchdulerList()
    }
  }, [page, sort])

  const getSchdulerList = (): void => {
    props.getSchdulerListDetails(
      page - 1,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      search,
      auth.user?.profile.customer as string
    )
  }

  const isFilterActive = (): boolean => {
    return schedulerName !== null || schedulerAssignments !== null || reportTemplates !== null || lastScheduleStatus !== null
  }

  React.useEffect(() => {
    props.getAllSchedulerFilterDropdown(auth.user?.profile.customer as string)
  }, [])

  const handleFetchSchedulerList = (): void => {
    if (page >= 1) {
      props.getSchdulerListDetails(
        page - 1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        search,
        auth.user?.profile.customer as string
      )
    }
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
    setSelectedRow(null)
  }

  const handleEllipsisClick = (
    event: any,
    row: string,
    rowName: string
  ): void => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
    sessionStorage.setItem('schedulerId', row != null ? row.toString() : '')
    sessionStorage.setItem('schedulerName', row != null ? rowName.toString() : '')
    setSelectedRowName(rowName)
  }

  const handleconfirmClick = (): void => {
    setconfirm(false)
    setshowSuccessPopUp(true)
    props.deleteSchedulerTemplate(selectedRow)
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof SchduledListData
  ): void => {
    const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'
    setSort({ order: toggledOrder, orderBy: newOrderBy })
  }

  const isSelected = (index: number): boolean => selected.includes(index)

  const onScheduleHistoryPopUpClose = (): void => {
    setShowScheduleHistoryPopUp(false)
  }

  React.useEffect(() => {
    if (schedulerNamesData !== undefined && schedulerNamesData.length > 0) {
      const schedulerNamesDataOptions: Option[] = []
      schedulerNamesData?.forEach((item: any) => {
        schedulerNamesDataOptions.push({
          value: item,
          label: item
        })
      })
      setschedulerNameOptions(schedulerNamesDataOptions)
    }
  }, [schedulerNamesData])

  React.useEffect(() => {
    if (
      schedulerAssignmentsData !== undefined &&
      schedulerAssignmentsData.length > 0
    ) {
      const schedulerAssignmentsDataOptions: Option[] = []
      schedulerAssignmentsData?.forEach((item: any) => {
        schedulerAssignmentsDataOptions.push({
          value: item.schedulerAssignmentId,
          label: item.schedulerAssignment
        })
      })
      setschedulerAssignmentsOptions(schedulerAssignmentsDataOptions)
    }
  }, [schedulerAssignmentsData])

  React.useEffect(() => {
    if (reportTemplatesData !== undefined && reportTemplatesData.length > 0) {
      const reportTemplatesDataOptions: Option[] = []
      reportTemplatesData?.forEach((item: any) => {
        reportTemplatesDataOptions.push({
          value: item.templateId,
          label: item.templateName
        })
      })
      setreportTemplatesOptions(reportTemplatesDataOptions)
    }
  }, [reportTemplatesData])

  React.useEffect(() => {
    if (ScheduleStatusData !== undefined && ScheduleStatusData.length > 0) {
      const ScheduleStatusDataOptions: Option[] = []
      ScheduleStatusData?.forEach((item: any) => {
        ScheduleStatusDataOptions.push({
          value: item,
          label: item
        })
      })
      setlastScheduleStatusOptions(ScheduleStatusDataOptions)
    }
  }, [ScheduleStatusData])

  const handleChooseSchedulerName = (selectedValue: Option): void => {
    setschedulerName(selectedValue)
    dispatch({ ...addState, schedulerName: [selectedValue.value] })
  }

  const handleschedulerAssignment = (selectedValue: Option): void => {
    setschedulerAssignments(selectedValue)
    dispatch({ ...addState, schedulerAssignments: [selectedValue.value] })
  }

  const handlereportTemplates = (selectedValue: Option): void => {
    setreportTemplates(selectedValue)
    dispatch({ ...addState, reportTemplates: [selectedValue.value] })
  }

  const handleScheduleStatus = (selectedValue: Option): void => {
    setlastScheduleStatus(selectedValue)
    dispatch({ ...addState, lastScheduleStatus: [selectedValue.value] })
  }

  const handleSchedulerList = (): void => {
    setschedulerName(null)
    setschedulerAssignments(null)
    setreportTemplates(null)
    setlastScheduleStatus(null)
    props.getSchdulerListDetails(
      page - 1,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      search,
      auth.user?.profile.customer as string
    )
  }

  const clearAllFilters = (): void => {
    setschedulerName(null)
    setschedulerAssignments(null)
    setreportTemplates(null)
    setlastScheduleStatus(null)
    setshowfilter(false)
    props.getSchdulerListDetails(
      page - 1,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      searchValue,
      auth.user?.profile.customer as string
    )
  }

  const clearAllfields = (): void => {
    setschedulerName(null)
    setschedulerAssignments(null)
    setreportTemplates(null)
    setlastScheduleStatus(null)
  }

  /* istanbul ignore next */
  const populateValues = (): void => {
    setshowfilter(true)
  }

  const showFilterDetails = (): void => {
    setshowfilter(true)
    props.getAllSchdulerListByFilterPara(
      page - 1,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      searchValue,
      {
        schedulerNames: schedulerName === null ? [] : [schedulerName.label],
        schedulerAssignments:
          schedulerAssignments === null ? [] : [schedulerAssignments.value],
        reportTemplates:
          reportTemplates === null ? [] : [reportTemplates.value],
        lastScheduleStatus:
          lastScheduleStatus === null ? [] : [lastScheduleStatus.label]
      },
      auth.user?.profile.customer as string
    )
    setOpenFilter(false)
  }

  const handleReportSchedulerEdit = (reportSchedulerId: string): void => {
    sessionStorage.setItem('reportSchedulerId', reportSchedulerId)
    navigate('/edit-report-scheduler')
  }

  const handleDelete = (): void => {
    setconfirm(true)
  }

  const handleTable = (): JSX.Element => {
    if (tableData !== undefined && dataHttpStatus === 200) {
      return (
        <>
          <Box sx={style} className="devices">
            <Paper
              sx={{
                width: '100%',
                mb: 2,
                backgroundColor: '#272727',
                boxShadow: 'unset',
                color: '#F0F0F0',
                marginBottom: '0'
              }}
            >
              <TableContainer className="device-table scheduler-list-table">
                <Table
                  sx={{ minWidth: 750, color: '#f0f0f0' }}
                  aria-labelledby="tableTitle"
                  size={'small'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={tableData.length}
                    SchduledList={tableData}
                  />
                  <TableBody>
                    {tableData.length > 0 &&
                      tableData.map((row: SchduledListData, index: number) => {
                        const isItemSelected = isSelected(index)
                        const labelId = `enhanced-table-checkbox-${row.reportSchedulerId}`
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.reportSchedulerId}
                            aria-label="devices-list-row"
                            selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                          >
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              aria-label="device-name"
                              // onClick={() => { handleRowClick(row.id.toString()) }}
                              data-testid="device-name"
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '25vw'
                              }}
                            >
                              {row.schedulerName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.templateName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.schedulerAssignment}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.assetOrGroupAssignments.length > 0
                                ? row.assetOrGroupAssignments[0]
                                  .assetOrGroupValue
                                : ''}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.schedulerInterval}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.recurrencePattern}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.lastScheduleDate !== null
                                ? parseDateInAOHstandard(
                                  row.lastScheduleDate
                                )
                                : ''}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.nextScheduleDate !== null
                                ? parseDateInAOHstandard(
                                  row.nextScheduleDate
                                )
                                : ''}
                            </StyledTableCell>
                            <StyledTableCell>
                           { row.lastScheduleStatus !== null
                             ? <div className={row.lastScheduleStatus.toLowerCase() === 'success' || row.lastScheduleStatus.toLowerCase() === 'progress' ? 'success-provision-outlined' : 'error-provision'} style={{
                               padding: '4px 8px', height: '24px', borderRadius: '16px'

                             }}>{row.lastScheduleStatus.toLowerCase() === 'success'
                               ? <TaskAltIcon sx={{ fontSize: 'medium', paddingRight: '3px' }} />
                               : row.lastScheduleStatus.toLowerCase() === 'progress'
                                 ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <g clipPath="url(#clip0_11236_368)">
                            <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.0498 8.7998C4.46402 8.7998 4.7998 8.46402 4.7998 8.0498C4.7998 7.63559 4.46402 7.2998 4.0498 7.2998C3.63559 7.2998 3.2998 7.63559 3.2998 8.0498C3.2998 8.46402 3.63559 8.7998 4.0498 8.7998Z" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.94995 8.7998C8.36416 8.7998 8.69995 8.46402 8.69995 8.0498C8.69995 7.63559 8.36416 7.2998 7.94995 7.2998C7.53574 7.2998 7.19995 7.63559 7.19995 8.0498C7.19995 8.46402 7.53574 8.7998 7.94995 8.7998Z" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.8501 8.7998C12.2643 8.7998 12.6001 8.46402 12.6001 8.0498C12.6001 7.63559 12.2643 7.2998 11.8501 7.2998C11.4359 7.2998 11.1001 7.63559 11.1001 8.0498C11.1001 8.46402 11.4359 8.7998 11.8501 8.7998Z" stroke="#303030" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </g>
                          <defs>
                            <clipPath id="clip0_11236_368">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                                 : <DoNotDisturb sx={{ fontSize: 'medium', paddingRight: '3px' }} />}<span style={{}}>{row.lastScheduleStatus === 'progress' ? 'In progress' : row.lastScheduleStatus}</span></div>
                             : ''}
                            </StyledTableCell>
                            <StyledTableCell>{row.schedulerStatus}</StyledTableCell>
                            <StyledTableCell>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <IconButton
                                  aria-controls={`menu-${index}`}
                                  aria-haspopup="true"
                                  data-testid="device-ellipsis"
                                  onClick={(event) => {
                                    handleEllipsisClick(
                                      event,
                                      row.reportSchedulerId,
                                      row.schedulerName
                                    )
                                  }}
                                  sx={{ padding: '0px !important' }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id={`menu-${row.reportSchedulerId}`}
                                  anchorEl={anchorEl}
                                  open={
                                    Boolean(anchorEl) &&
                                    selectedRow === row.reportSchedulerId
                                  }
                                  onClose={handleMenuClose}
                                >
                                  { row.schedulerStatus === 'RUNNING'
                                    ? (
                                      <MenuItem
                                        data-testid="pause-schedule-btn"
                                        onClick={() => {
                                          handlePauseClick()
                                          setAnchorEl(null)
                                        }}
                                      >
                                        {SchedulerTableForms.pausebtn}
                                        </MenuItem>
                                      )
                                    : row.schedulerStatus === 'PAUSED'
                                      ? (
                                      <MenuItem data-testid="pause-schedule-btn"
                                        onClick={() => {
                                          handleResumeClick()
                                          setAnchorEl(null)
                                        }}
                                      >
                                        {SchedulerTableForms.resumebtn}
                                        </MenuItem>
                                        )
                                      : '' }
                                  <MenuItem
                                    data-testid="history-schedule-btn"
                                    onClick={() => {
                                      setShowScheduleHistoryPopUp(true)
                                      setAnchorEl(null)
                                    }}
                                  >
                                    View History
                                  </MenuItem>
                                  { row.schedulerStatus !== 'COMPLETED'
                                    ? (
                                      <MenuItem
                                        data-testid="edit-schedule-btn"
                                        onClick={() => {
                                          handleReportSchedulerEdit(row.reportSchedulerId)
                                          setAnchorEl(null)
                                        }}
                                      >
                                        Edit
                                        </MenuItem>
                                      )
                                    : '' }
                                  <MenuItem
                                    data-testid="Delete-schedule-btn"
                                    onClick={() => {
                                      handleDelete()
                                      setAnchorEl(null)
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={totalCount}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                siblingCount={1}
                boundaryCount={1}
              />
            </Paper>
          </Box>
        </>
      )
    } else if (props.SchdulerListData.isLoading) {
      return (
        <div className="CircularprogressAmi">
          <CircularProgress />
        </div>
      )
    } else if (dataHttpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (dataHttpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else if (dataHttpStatus === 404) {
      return (
        <div className="authError">
          <AuthError errorMessage="NoDataPresent" />
        </div>
      )
    } else {
      return (
        <div className="authError">
          <AuthError
            errorMessage="cannotFetch"
            retry={handleFetchSchedulerList}
          />
        </div>
      )
    }
  }

  const handleOkandClose = (): void => {
    if (SchedulerDeleterStatus.httpStatus === 200) {
      sessionStorage.setItem('currentSessionTab', '1')
      setshowSuccessPopUp(false)
      setconfirm(false)
      navigate('/datapub')
      props.getSchdulerListDetails(
        page - 1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        search,
        auth.user?.profile.customer as string
      )
    } else {
      setshowSuccessPopUp(false)
      setconfirm(false)
    }
  }

  const handleDeletechedulrWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (SchedulerDeleterStatus.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={SchedulerTableForms.deleteBoxTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandClose()
          }}
          onClose={() => {
            handleOkandClose()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {SchedulerDeleterStatus.httpStatus === 200
              ? SchedulerTableForms.deleteMsg
              : SchedulerDeleterStatus.error}
          </p>
        </Modal>
      )
    } else if (SchedulerDeleterStatus.isLoading) {
      return (
        <Modal title={'Loading...'}>
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (SchedulerDeleterStatus.httpStatus === 400) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={SchedulerTableForms.ErrorTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandClose()
          }}
          onClose={() => {
            handleOkandClose()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{SchedulerDeleterStatus.error}</p>
        </Modal>
      )
    } else if (SchedulerDeleterStatus.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (SchedulerDeleterStatus.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={SchedulerTableForms.deleteBoxTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandClose()
          }}
          onClose={() => {
            handleOkandClose()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{SchedulerDeleterStatus.error}</p>
        </Modal>
      )
    }
  }

  const handlePauseClick = (): void => {
    props.pauseSchedulerTemplate(selectedRow)
    setshowSuccessPausePopUp(true)
    handlePauseSchedulrWithFailure()
  }

  const handleResumeClick = (): void => {
    props.resumeSchedulerTemplate(selectedRow)
    setshowSuccessResumePopUp(true)
    handleResumeSchedulrWithFailure()
  }

  const handleOkandClosePause = (): void => {
    if (pauseSchedulerStatus.httpStatus === 200) {
      sessionStorage.setItem('currentSessionTab', '1')
      setshowSuccessPausePopUp(false)
      navigate('/datapub')
      props.getSchdulerListDetails(
        page - 1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        search,
        auth.user?.profile.customer as string
      )
    } else {
      setshowSuccessPausePopUp(false)
    }
  }

  /* istanbul ignore next */
  const handleSearch = (): void => {
    if (page >= 1) {
      props.getSchdulerListDetailsBySearch(page - 1, rowsPerPage, sort.order, sort.orderBy, searchValue, auth.user?.profile.customer as string)
    }
  }

  const handlePauseSchedulrWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (pauseSchedulerStatus.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={SchedulerTableForms.SchedulerResumePasueTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandClosePause()
          }}
          onClose={() => {
            handleOkandClosePause()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {pauseSchedulerStatus.httpStatus === 200
              ? 'Scheduler paused sucessfully'
              : pauseSchedulerStatus.error}
          </p>
        </Modal>
      )
    } else if (SchedulerDeleterStatus.isLoading) {
      return (
        <Modal title={'Loading...'}>
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (pauseSchedulerStatus.httpStatus === 400) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={SchedulerTableForms.SchedulerPause}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandClosePause()
          }}
          onClose={() => {
            handleOkandClosePause()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{SchedulerDeleterStatus.error}</p>
        </Modal>
      )
    } else if (pauseSchedulerStatus.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (pauseSchedulerStatus.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={'Scheduler Status'}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandClosePause()
          }}
          onClose={() => {
            handleOkandClosePause()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{pauseSchedulerStatus.error}</p>
        </Modal>
      )
    }
  }

  const handleOkandCloseResume = (): void => {
    if (resumeSchedulerStatus.httpStatus === 200) {
      sessionStorage.setItem('currentSessionTab', '1')
      setshowSuccessResumePopUp(false)
      navigate('/datapub')
      props.getSchdulerListDetails(
        page - 1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        searchValue,
        auth.user?.profile.customer as string
      )
    } else {
      setshowSuccessResumePopUp(false)
    }
  }

  const handleResumeSchedulrWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (resumeSchedulerStatus.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={SchedulerTableForms.SchedulerResumePasueTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandCloseResume()
          }}
          onClose={() => {
            handleOkandCloseResume()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {resumeSchedulerStatus.httpStatus === 200
              ? 'Scheduler resumed sucessfully'
              : resumeSchedulerStatus.error}
          </p>
        </Modal>
      )
    } else if (resumeSchedulerStatus.isLoading) {
      return (
        <Modal title={'Loading...'}>
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (resumeSchedulerStatus.httpStatus === 400) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={SchedulerTableForms.SchedulerResumePasueTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandCloseResume()
          }}
          onClose={() => {
            handleOkandCloseResume()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{SchedulerDeleterStatus.error}</p>
        </Modal>
      )
    } else if (resumeSchedulerStatus.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (resumeSchedulerStatus.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={SchedulerTableForms.SchedulerResumePasueTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandCloseResume()
          }}
          onClose={() => {
            handleOkandCloseResume()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{resumeSchedulerStatus.error}</p>
        </Modal>
      )
    }
  }

  return (
    <>
      <div
        className="searchSection scheduler-grid"
        style={{ width: '99%', backgroundColor: '#272727' }}
      >
        <Paper
          className="searchBoxComponent"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
          <Button
            data-testid="filter-data"
            className="filter-btn"
            sx={{ background: '#707070', color: '#D0D0D0' }}
            onClick={() => {
              setOpenFilter(true)
            }}
          >
            <img
              src={FilterIcon}
              height={16}
              width={16}
              style={{ marginLeft: '7px' }}
            />
            {SchedulerTable.FilterBtn}
          </Button>
          <SearchBox
            immediate={true}
            debounce={true}
            placeholder="Search using Schedule Name, Group/Asset"
            sx={{ width: '450px' }}
            emptyEnterFallback={() => {
              if (page >= 1) {
                props.getSchdulerListDetails(
                  page - 1,
                  rowsPerPage,
                  sort.order,
                  sort.orderBy,
                  searchValue,
                  auth.user?.profile.customer as string
                )
              }
            }}
            searchVal={searchValue}
            setSearchVal={setSearchValue}
            performAction={() => { handleSearch() }}
          />
          <div className="download-grid">
            <Tooltip title="Download">
              <IconButton
                data-testid="exportFile"
                onClick={() => {
                  props.exportSchedulerGridTemplate(
                    auth.user?.profile.customer as string
                  )
                }}
                sx={{ position: 'absolute', right: '25px', marginTop: '-20px' }}
              >
                <img src={FileExport} height={25} width={25} />{' '}
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
        {showfilter && (
          <div
            style={{
              padding: '12px 24px',
              borderTop: '2px solid #202020',
              color: '#d0d0d0'
            }}
          >
            {schedulerName === null &&
              schedulerAssignments === null &&
              reportTemplates === null &&
              lastScheduleStatus === null
              ? (<>
                  <span>No filters applied</span> <em style={{ cursor: 'pointer', color: '#1792e5', paddingLeft: '10px' }} onClick={() => { clearAllFilters() }}>Clear {' '}</em>
                  </>
                )
              : (
              <div className="classNAme">
                {totalCount} Results | Filters :
                <>
                  {schedulerName !== null && (
                    <div className="box">
                      {' '}
                      {SchedulerTable.ScheduleName} : {schedulerName.label}
                    </div>
                  )}
                  {schedulerAssignments !== null && (
                    <div className="box">
                      {' '}
                      {SchedulerTable.schedulerAssignment} :{' '}
                      {schedulerAssignments.label}
                    </div>
                  )}
                  {reportTemplates !== null && (
                    <div className="box">
                      {' '}
                      {SchedulerTable.ReportTemplate}: {reportTemplates.label}
                    </div>
                  )}
                  {lastScheduleStatus !== null && (
                    <div className="box">
                      {' '}
                      {SchedulerTable.LastScheduleStatus}:{' '}
                      {lastScheduleStatus.label}
                    </div>
                  )}
                </>
                <em
                  onClick={() => {
                    clearAllFilters()
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Clear Filters{' '}
                </em>
              </div>
                )}
          </div>
        )}
      </div>
      <Drawer
        anchor="right"
        open={openFilter}
        className="filter-drawer"
        variant="temporary"
        onClose={() => {
          setOpenFilter(false)
        }}
      >
        <div className="filter-headar">
          <div className="title" style={{ marginTop: '15px' }}>
            Filter
          </div>
          <div>
            <Button
              aria-label="clear-all-filter"
              className="clear-btn"
              onClick={() => {
                clearAllfields()
              }}
            >
              <ReplayIcon
                sx={{ width: '16px', height: '16px', marginRight: '8px' }}
              />
              Clear All
            </Button>
          </div>
          <div>
            <CloseIcon
              aria-label="close-drawer"
              sx={{ color: '#f0f0f0' }}
              onClick={() => {
                setOpenFilter(false)
              }}
            />
          </div>
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <div className="type-name" style={{ marginTop: '40px' }}>
              {SchedulerTable.SchedulerName}
            </div>
            <GenXDropdown
              placeholder="Select Name"
              value={schedulerName}
              options={schedulerNameOptions}
              onSelect={handleChooseSchedulerName}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="type-name" style={{ marginTop: '20px' }}>
              {SchedulerTable.ReportTemplate}
            </div>
            <GenXDropdown
              placeholder="Select Template"
              value={reportTemplates}
              options={reportTemplatesOptions}
              onSelect={handlereportTemplates}
            />
          </Grid>

          <Grid item xs={12}>
            <div className="type-name" style={{ marginTop: '20px' }}>
              {SchedulerTable.schedulerAssignment}
            </div>
            <GenXDropdown
              placeholder="Select Assignment"
              value={schedulerAssignments}
              options={schedulerAssignmentsOptions}
              onSelect={handleschedulerAssignment}
            />
          </Grid>

          <Grid item xs={12}>
            <div className="type-name" style={{ marginTop: '20px' }}>
              {SchedulerTable.LastScheduleStatus}
            </div>
            <GenXDropdown
              placeholder="Select Status"
              value={lastScheduleStatus}
              options={lastScheduleStatusOptions}
              onSelect={handleScheduleStatus}
            />
          </Grid>
        </Grid>
        <div className="buttons-container">
          <Button
            className="cancel"
            aria-label="cancel-drawer"
            onClick={() => {
              setOpenFilter(false)
              handleSchedulerList()
            }}
          >
            {' '}
            {SchedulerTable.CancelBtn}
          </Button>
          <Button
            aria-label="confirm-filter"
            data-testid="show-results"
            onClick={() => {
              showFilterDetails()
              populateValues()
            }}
          >
            {SchedulerTable.ShowResult}
          </Button>
        </div>
      </Drawer>
      {showScheduleHistoryPopUp && (
        <ReportViewHistory
          onScheduleHistoryPopUpClose={onScheduleHistoryPopUpClose}
        />
      )}
      {confirm && (
        <Modal
          titleStyle={{ paddingRight: '2em', marginBottom: '20px' }}
          buttonTypeNegative="hollow"
          LabelPositive={SchedulerTable.ConfirmBtn}
          LabelNegative={SchedulerTable.CancelBtn}
          onClickPositive={() => {
            handleconfirmClick()
          }}
          onClose={() => {
            setconfirm(false)
          }}
          onClickNegative={() => {
            setconfirm(false)
          }}
          title={SchedulerTableForms.deleteBoxTitle}
        >
          <div className="confirm-msg" style={{ marginBottom: '0px', minHeight: '50px' }}>
            <span>
              {SchedulerTableForms.deleteConfirmMsg} : {selectedRowName}
            </span>
          </div>
        </Modal>
      )}
      {showSuccessPopUp && handleDeletechedulrWithFailure()}
      {showSuccessPausePopUp && handlePauseSchedulrWithFailure()}
      {showSuccessResumePopUp && handleResumeSchedulrWithFailure()}
      {handleTable()}
    </>
  )
}

interface DispatchToProps {
  getSchdulerListDetails: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    search: string,
    tenantId: string
  ) => void
  getAllSchedulerFilterDropdown: (tenantId: string) => void
  getAllSchdulerListByFilterPara: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    input: string,
    filterParams: any,
    tenantId: string
  ) => void
  getSchdulerListDetailsBySearch: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    input: string,
    tenantId: string
  ) => void
  exportSchedulerGridTemplate: (tenantId: string) => void
  deleteSchedulerTemplate: (schedulerId: string) => void
  pauseSchedulerTemplate: (schedulerId: string) => void
  resumeSchedulerTemplate: (schedulerId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getSchdulerListDetails: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    search: string,
    tenantId: string
  ) =>
    dispatch(
      getSchdulerListDetails(page, size, sortdir, sortfield, search, tenantId)
    ),
  getAllSchedulerFilterDropdown: (tenantId: string) =>
    dispatch(getAllSchedulerFilterDropdown(tenantId)),
  getAllSchdulerListByFilterPara: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    input: string,
    filterParams: any,
    tenantId: string
  ) =>
    dispatch(
      getAllSchdulerListByFilterPara(
        page,
        size,
        sortdir,
        sortfield,
        input,
        filterParams,
        tenantId
      )
    ),
  getSchdulerListDetailsBySearch: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    input: string,
    tenantId: string
  ) =>
    dispatch(
      getSchdulerListDetailsBySearch(
        page,
        size,
        sortdir,
        sortfield,
        input,
        tenantId
      )
    ),
  exportSchedulerGridTemplate: (tenantId: string) =>
    dispatch(exportSchedulerGridTemplate(tenantId)),
  deleteSchedulerTemplate: (schedulerId: string) =>
    dispatch(deleteSchedulerTemplate(schedulerId)),
  pauseSchedulerTemplate: (schedulerId: string) =>
    dispatch(pauseSchedulerTemplate(schedulerId)),
  resumeSchedulerTemplate: (schedulerId: string) =>
    dispatch(resumeSchedulerTemplate(schedulerId))
})

interface StateToProps {
  SchdulerListData: SchdulerListDataState
  SchedulerFilterDropDownListData: CreateSchedulerFilterDropDownState
  DeleteSchedulerTemplateState: DeleteSchedulerTemplateState
  PauseSchedulerTemplateState: PauseSchedulerTemplateState
  ResumeSchedulerTemplateState: resumeSchedulerTemplateState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  SchdulerListData: state.SchdulerListData,
  SchedulerFilterDropDownListData: state.SchedulerFilterDropDownListData,
  DeleteSchedulerTemplateState: state.deleteScheduler,
  PauseSchedulerTemplateState: state.pauseScheduler,
  ResumeSchedulerTemplateState: state.resumeScheduler
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ReportScheduleTableList)
